export default defineI18nConfig(() => ({
    messages: {
        de: {
            buttonPrevLabel: 'Zurück zum vorherigen Slide',
            buttonNextLabel: 'Weiter zum nächsten Slide',
            buttonToIndexLabel: 'Zum Slide an der Position {index} wechseln',
        },
        en: {
            buttonPrevLabel: 'Back to previous slide',
            buttonNextLabel: 'Go to next slide',
            buttonToIndexLabel: 'Go to slide at position {index}',

        },
        fr: {
            buttonPrevLabel: 'Retour au diapositive précédente',
            buttonNextLabel: 'Aller à la diapositive suivante',
            buttonToIndexLabel: 'Aller à la diapositive à la position {index}',
        },
    },
}));
